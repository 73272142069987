.table {
  border-collapse: collapse;

  .product-image {
    width: 141px;
    height: 41px;
    border-radius: 10px;
    margin-right: 18.9px;
  }

  .header {
    td {
      text-align: left;
    }
  }

  tr {
    border: 1px solid rgba(126, 148, 171, 0.1);
    height: 46px;
  }

  // .button-box {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     height: 100%;
  // }

  .remove-btn {
    padding: 4px;
  }

  .availability {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #3853a3;
    width: 135px;
    height: 36px;
    border-radius: 10px;
    border: none;
    outline: none;
  }

  .green {
    background: #bfe795;
  }
}

// CREATE NEW PRODUCT
