.subscribed {
  opacity: 1;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  position: absolute;
  top: 60px;
  left: 22px;
  color: #3853a3;
  transition: 0.5s all ease;
}

.appear {
  position: absolute;
  transition: 0.5s all ease;
  opacity: 0;
}
