.product-section {
  padding-top: 140px;

  .gray-matter {
    position: absolute;
    width: 100%;
    height: 232px;
    left: 0px;
    top: 0px;
    background-color: #f2f2f2;
    .text {
      margin: 163px 0 39px 224px;
      @media only screen and (max-width: 450px) {
        margin: auto;
        margin-top: 100px;
        align-items: center;
        gap: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .shop-items-container {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 258px;
    margin-top: 89px;
    width: 1500px;

    @media only screen and (max-width: 1500px) {
      width: 80%;
    }
    @media only screen and (max-width: 500px) {
      margin-bottom: 100px;
    }

    .single-item {
      border-radius: 20px;
      margin: 0 0 42px 0;

      @media only screen and (max-width: 1500px) {
        margin: 0 auto;
      }

      .single-shop-item-content {
        display: flex;
        align-items: flex-start;

        @media only screen and (max-width: 1500px) {
          flex-direction: column;
          align-items: center;
        }

        // padding: 31px 30px 26px 30px;

        .images-wrapper {
          @media only screen and (max-width: 1500px) {
            display: flex;
            flex-direction: column-reverse;
          }
        }

        .pictures {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 16px;

          @media only screen and (max-width: 1500px) {
            align-items: flex-start;
            justify-content: center;
            flex-direction: row;
            margin-top: 15px;
            margin-right: 0;
            gap: 5px;
            order: 2;
          }
          @media only screen and (max-width: 450px) {
            flex-wrap: wrap;
          }

          .picture-box {
            cursor: pointer;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }

            img {
              width: 80px;
              height: 80px;
              border: 2px solid #3853a3;
              border-radius: 25px;
              object-fit: cover;
              @media only screen and (max-width: 480px) {
                width: 84px;
                height: 84px;
              }
            }
          }
        }

        .image-box {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 25px;
          background-color: #fff;
          border: 2px solid #3853a3;
          @media only screen and (max-width: 1024px) {
            width: 100%;
            height: 100%;
            order: 1;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 25px;
          }
        }

        .image-box-smaller {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 25px;
          background-color: #fff;
          border: 2px solid #3853a3;
          @media only screen and (max-width: 1024px) {
            width: 300px;
            height: 300px;
            order: 1;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 25px;
          }
        }

        .text {
          margin-left: 47px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 25px;
          @media only screen and (max-width: 1500px) {
            margin-top: 20px;
            margin-left: 0;
            order: 3;
            align-items: center;
          }

          .single-item-header {
            font-size: 45px;
            margin-bottom: 7px;
            @media only screen and (max-width: 768px) {
              font-size: 35px;
            }
          }
          .single-item-desc {
            font-size: 18px;
            margin-bottom: 7px;
            width: 450px;
            .view {
              font-size: 7px;
              cursor: pointer;
            }
            @media only screen and (max-width: 450px) {
              text-align: center;
              width: 100%;
            }
          }

          .price {
            font-size: 40px;
            margin-bottom: 7px;
            @media only screen and (max-width: 768px) {
              font-size: 35px;
            }
          }
        }
      }

      .add-to-cart-btn {
        background-color: transparent;
        border: 2px solid #ffa800;
        border-radius: 100px;
        transition: 0.3s all ease;

        &:hover {
          background-color: #3853a3;
          border: 2px solid transparent;
          transition: 0.3s all ease;

          .text {
            color: #fff;
          }
        }

        cursor: pointer;

        .box {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 20px;

          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }

          .text {
            font-size: 16px;
            line-height: 23px;
            margin: 0;
            font-weight: 300;
          }
        }
      }
    }
  }
}

.shop-name-destination {
  margin-right: 20px;
  @media only screen and (max-width: 450px) {
    margin-right: 0;
  }
}

.pic-and-image-box {
  @media only screen and (min-width: 450px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 705px;
    gap: 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outofstock {
  background-color: gray;
  
}