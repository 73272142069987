@import url("https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,200;9..144,300;9..144,400;9..144,500;9..144,600;9..144,700&display=swap");

@font-face {
  font-family: "Intel Clear Pro";
  src: url("../fonts/IntelClearPro_Bd.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Intel Clear Pro";
  src: url("../fonts/IntelClear_BdIt.ttf");
  font-style: italic;
  font-weight: 800;
}
@font-face {
  font-family: "Intel Clear Pro";
  src: url("../fonts/IntelClear_It.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Intel Clear Pro";
  src: url("../fonts/IntelClear_Lt.ttf");
  font-weight: 30;
}
@font-face {
  font-family: "Intel Clear Pro";
  src: url("../fonts/IntelClear_LtIt.ttf");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: "Intel Clear Pro";
  src: url("../fonts/IntelClear_Rg.ttf");
  font-weight: 500;
}
@import url("https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght@9..144,200;9..144,300;9..144,400;9..144,500;9..144,600;9..144,700&family=Jost:wght@200;300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}
html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
}

.home {
  padding: 21.9px 39.5px;
}

.gray-text-normal {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #7e94ab;
}

.flex-center {
  display: flex;
  align-items: center;
}

.loading {
  font-size: 27px;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  color: #687b90;
  font-size: 14px;
  padding-bottom: 50px;
}
.loading-empty {
  width: 300px;
  height: 300px;
  background-color: transparent;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid white;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin-left: 110px;
}

.loading-spinner-smaller {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid white;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-empty-little {
  background-color: transparent;
}

@media only screen and (max-width: 480px) {
  #footer-logo {
    display: none;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  #footer-logo {
    max-width: 100px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #footer-logo {
    max-width: 170px;
  }
}

#logo2 {
  line-height: 0;
  background-color: #FFFFFFb3;
  border-radius: 20px;
  padding: 10px;
  width: 250px;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 660px) {
  
#fLogoInfoBig {
 display: none !important;
}

#fLogoInfoSmall {
  font-size: small;
  line-height: 14px;
 }

 #logo2 {
  margin-top: 1.5rem;
  width: 150px;
  margin-bottom: .25rem;
 }

}

@media only screen and (min-width: 660px) {
  
#fLogoInfoSmall {
 display: none !important;
}

#logo2 {
  width: 200px;
  margin-bottom: 2rem;
 }
 #fLogoInfoBig {
  font-size: small;
  line-height: 4px;
 }
}

#sup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #FFFFFF;
  font-weight: 300;  
  font-family: Jost;
}

#bankWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

}

#bank {
  padding: .25rem;
  margin: 1rem 3rem;
  object-fit: inherit;
  height: 70px;

}
@media only screen and (max-width: 700px) {
  #bank {
   height: 50px;
   margin: .25rem;
  }

  #sup {
    margin-top: 2rem;
  }

  #bankWrapper {
    flex-direction: column;
  }
}

@media only screen and (min-width: 701px) and (max-width: 1200px) {
  #bank {
   height: 50px;
   margin: .25rem 1.5rem;
  }

  #bankWrapper {
    flex-direction: column;
  }
}
@media only screen and (max-width: 460px) {
  
  #scroll_arrow {
    display: none;
  }

  }