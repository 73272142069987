.table {
  border-collapse: collapse;

  .product-image {
    width: 41px;
    height: 41px;
    border-radius: 10px;
    margin-right: 18.9px;
  }

  tr {
    border: 1px solid rgba(126, 148, 171, 0.1);
    height: 46px;
  }

  .remove-btn {
    padding: 4px;
  }

  .availability {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #3853a3;
    width: 135px;
    height: 36px;
    border-radius: 10px;
    border: none;
    outline: none;
  }

  .green {
    background: #bfe795;
  }
}

// CREATE NEW PRODUCT

.content {
  width: 1000px;
  padding-top: 16px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1450px) {
    width: 100%;
    margin: 47px auto;
  }
  .box {
    display: flex;
    padding: 50px 0 30px 10px;
    justify-content: center;
    gap: 20px;
    @media only screen and (max-width: 1050px) {
      flex-direction: column;
      padding: 20px;
    }
  }
  .header {
    font-family: "Jost";
    font-style: normal;
    font-weight: 600;
    color: #3853a3;
    display: inline;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    margin-left: 20px;
  }

  img {
    height: 280px;
    width: 280px;
    @media only screen and (max-width: 45px) {
    }
  }

  //create image
  .shop-image-box {
    position: relative;
    transition: 0.3s all ease;
    height: 280px;
    width: 280px;
    @media screen and (max-width: 1050px) {
      width: 100%;
    }

    &:after {
      transition: 0.3s all ease;
      position: absolute;
      content: "";
      background: #fff;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }

    &:hover {
      transition: 0.3s all ease;

      &:after {
        opacity: 0.5;
      }

      .text {
        transition: 0.3s all ease;
        background-color: #3853a3;
        display: flex;
        flex-direction: column;

        label {
          transition: 0.3s all ease;
          color: #fff;
        }
      }
    }

    img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .star {
      position: absolute;
      width: 43px;
      height: 43px;
      top: -20px;
      left: -15px;
      z-index: 4;
    }

    .text {
      position: absolute;
      background-color: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      z-index: 3;
      height: 50px;
      cursor: pointer;
      display: flex;
        flex-direction: column;

      input[type="file"] {
        display: none;
      }

      > label {
        display: inline-block;
        cursor: pointer;
        font-size: 20px;
        font-family: "Jost";
        font-style: normal;
        font-weight: 600;
        color: #3853a3;
      }
    }
  }
  .shop-image-box-loading {
    @extend .shop-image-box;
    &:after {
      opacity: 0.5;
    }
    .text {
      transition: 0.3s all ease;
      background-color: #3853a3;

      label {
        transition: 0.3s all ease;
        color: #fff;
      }
    }
  }
  //no shop
  .empty-image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 47px;
    margin-bottom: 32px;
    @media only screen and (max-width: 1100px) {
      width: 100%;
      flex-direction: column;
    }

    .no-shop {
      width: 600px;
      height: 274px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(126, 148, 171, 0.1);
      border-radius: 20px;
      @media only screen and (max-width: 1100px) {
        width: 80%;
      }

      .no-shop-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
  .create-shop-textarea {
    transition: 0.5s all ease;
    width: 100%;
    border-radius: 20px;
    padding: 20px 40px 0 20px;
    outline: none;
    border: none;
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    height: 122px;
    display: block;
    position: relative;
    z-index: 2;
  }

  .edit-box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 47px;
    gap: 43px;
    padding-bottom: 65px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      width: 90%;
      flex-direction: column;
      align-items: center;
      margin: 47px auto;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1450px) {
      width: 90%;
      margin: 47px auto;
    }
    .no-shop {
      width: 600px;
      height: 274px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(126, 148, 171, 0.1);
      border-radius: 20px;

      .no-shop-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }
  }
}

.your-shop-content {
  padding-top: 20px;
  width: 1000px;
  @media only screen and (max-width: 1450px) {
    width: 100%;
  }

  .your-shop-header {
    font-family: "Jost";
    font-style: normal;
    font-weight: 600 !important;
    color: #3853a3;
    display: inline;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    margin-left: 20px;
    @media only screen and (max-width: 450px) {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 1450px) {
    text-align: center;
  }

  //Your shop
  .your-image {
    width: 250px;
    height: 278px;
    border-radius: 10px;
  }

  .your-shop-box {
    display: flex;
    justify-content: center;
    margin-top: 47px;
    padding-bottom: 65px;
    gap: 47px;

    @media only screen and (max-width: 1024px) {
      width: 90%;
      flex-direction: column;
      align-items: center;
      margin: 47px auto;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1450px) {
      width: 90%;
      margin: 47px auto;
    }
    .your-shop-box-img {
      width: 280px;
      height: 280px;
      border-radius: 20px;
      object-fit: cover;
    }

    .shop-info {
      width: 530px;
      height: 280px;
      border-radius: 20px;
      background: rgba(126, 148, 171, 0.1);
      padding-bottom: 20px;

      @media only screen and (max-width: 1450px) {
        width: 100%;
        margin: 0 auto;
        height: 100%;
      }

      .shop-info-content {
        padding: 20px 28px 00px 28px;

        @media only screen and (max-width: 480px) {
          width: 100%;
          padding: 20px;
        }

        .your-shop-text {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          @media only screen and (max-width: 480px) {
            align-items: center;
          }

          .your-shop-description-box {
            height: 67px;
            width: 100%;
            overflow-y: scroll;

            @media only screen and (max-width: 756px) {
              height: 100%;
            }

            ::-webkit-scrollbar-track {
              background: rgba(88, 105, 123, 0.1);
            }
          }

          .your-shop-description-box::-webkit-scrollbar {
            width: 5px;
          }

          .your-shop-description-box::-webkit-scrollbar {
            background: transparent;
          }

          .your-shop-description-box::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: rgba(6, 17, 31, 0.31);
          }
        }

        .buttons {
          display: flex;
          justify-content: center;
          gap: 16px;
          width: 100%;

          button {
            width: 143px;
            height: 33px;
            @media only screen and (max-width: 480px) {
              width: 100px;
            }
          }
        }
        .your-shop-buttons {
          display: flex;
          justify-content: center;
          gap: 16px;
          width: 100%;
          margin-top: 20px;
          button {
            width: 123px;
            height: 33px;
            @media only screen and (max-width: 450px) {
              width: 123px;
              height: 33px;
            }
          }
        }
      }
    }
  }
}

.create-shop-btn {
  &:first-child {
    margin-right: 10px;
  }

  width: 120px;
  height: 33px;
  border-radius: 10px;
}

.premium-plan-btn {
  height: 33px;
  padding: 0 10px;
}

.button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 13px;
  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
}

.create-shop-button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  margin-top: 100px;
}

// edit shop
.input-shop {
  width: 530px;
  position: relative;
  display: flex;
  margin-bottom: 24px;
  height: 54px;
  outline: none;
  border: none;
  @media only screen and (max-width: 480px) {
    width: 300px;
    margin-inline: auto;
  }
  @media only screen and (min-width: 481px) and (max-width: 1050px) {
    width: 100%;
    margin-inline: auto;
  }

  .edit-shop-textarea {
    transition: 0.5s all ease;
    width: 100%;
    border-radius: 20px;
    padding: 20px 40px 0 20px;
    outline: none;
    border: none;
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    height: 117px;
    position: relative;
    z-index: 2;
    &::placeholder {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      color: #3853a3;
      transition: 0.5s all ease;
      height: 30px;
    }
  }

  input {
    transition: 0.5s all ease;
    width: 100%;
    border-radius: 50px;
    padding: 0 0 0 20px;
    height: 100%;
    margin-bottom: 27px;
    color: #374366;
    position: relative;
    border-radius: 20px;
    color: #0e1425;
    border: 1px solid #000;
    outline: none;
    border: none;
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    z-index: 5;

    &::placeholder {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      color: #3853a3;
      transition: 0.5s all ease;
      height: 30px;
    }
  }
  .change-password-input {
    font-size: 18px;
  }

  img {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 10px;
    top: 13px;
    z-index: 5;
    cursor: pointer;
    opacity: 1;
    transition: 0.5s all ease;
  }

  .placeholder-box {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    left: 22px;
    transition: 0.5s all ease;

    .placeholder {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      color: #3853a3;
      transition: 0.5s all ease;
      height: 30px;
    }
  }
}
.edit-box-response {
  position: absolute;
  bottom: 71px;
  left: 90px;

  @media only screen and (max-width: 450px) {
    bottom: -5px;
    left: 50 px;
  }
}
.create-box-response {
  position: absolute;
  bottom: -60px;
  left: 35px;
  width: 100%;
}

.phone-input-box {
  width: 494px;
  height: 54px;
  background: rgba(114, 139, 166, 0.2);
  margin-bottom: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 3;
  img {
    width: 24px;
    height: 20px;
  }
  .PhoneInputCountry {
    margin: 0 0 5px 20px;
    gap: 5px;
  }
  .PhoneInputCountrySelectArrow {
  }
  .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: none;
    border: none;
    background-color: none;
  }
  select {
    position: absolute;
    z-index: 1;
    border-radius: 20px;
    height: 36px;
    background-color: rgba(114, 139, 166, 0.4);
    line-height: 25px;
    width: 450px;
    @media only screen and (max-width: 450px) {
      width: 150px;
    }
  }
  option {
    width: 400px;
  }

  input {
    left: 80px;
    position: absolute;
    z-index: 2;
    outline: none;
    border: none;
    height: 30px;
    background-color: transparent;
    width: 380px;
    color: #374366;
    &::placeholder {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #3853a3;
    }
    @media only screen and (max-width: 450px) {
      width: 170px;
    }
  }
}

.loading-spinner-smaller {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shop-created {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  color: #3853a3;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 200px;
}

.profile_settings_btn {
  &:first-child {
    margin-right: 10px;
  }

  width: 120px;
  height: 33px;
  border-radius: 10px;
}

.btn_wrapper {
  width: 100%;
  display: flex;
  justify-content: end;
}