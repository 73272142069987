.more-news-page-section {
  padding-top: 112px;

  @media only screen and (max-width: 768px) {
    padding-top: 30px;
    .content {
      padding-top: 0;
    }
  }
  .header {
    font-size: 50px;
    width: 300px;
    line-height: 180px;
    text-align: center;
    display: block;
    margin: auto;
    @media only screen and (max-width: 768px) {
      font-size: 3rem;
    }
  }

  .content::-webkit-scrollbar {
    background: #3853a3;
    border-radius: 20px;
    width: 15px;
  }

  .content::-webkit-scrollbar-thumb {
    border-radius: 20px;
    width: 5px;
    // margin-right: ;
    background: #ffa800;
  }

  .content {
    width: 1300px;
    height: 800px;
    margin: auto;
    margin-bottom: 150px;
    overflow-y: scroll;

    @media only screen and (max-width: 1400px) {
      width: 80%;
    }

    .news-box {
      height: 301px;
      border-radius: 10px;
      margin-bottom: 45px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:last-child {
        margin-bottom: 0;
      }

      width: 93%;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        height: 80%;
      }

      img {
        border-radius: 25px 0 0 25px;
        height: 100%;
        width: 380px;
        object-fit: cover;

        @media only screen and (max-width: 1200px) {
          width: 100%;
          border-radius: 25px 25px 0 0;
          min-height: inherit;
        }
      }

      .news-box-text {
        background: #7e94ab;
        height: 100%;
        width: 100%;
        border-radius: 0 25px 25px 0;

        @media only screen and (max-width: 1200px) {
          min-height: inherit;
          border-radius: 0 0 25px 25px;
        }

        .text-content {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding: 20px 50px;

          @media only screen and (max-width: 1200px) {
            padding: 20px;
          }

          .date {
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            color: rgb(205, 205, 205);
            margin-bottom: 7px;

            @media (max-width: 1600px) {
              font-size: 14px;
            }
          }

          .category {
            font-family: "Jost";
            font-style: normal;
            font-weight: 700;
            color: #ff9900;
            margin-bottom: 7px !important;
            font-size: 18px !important;

            @media (max-width: 1600px) {
              font-size: 14px;
            }
          }

          .text-header {
            font-size: 18px;
            margin-bottom: 7px;
          }

          p {
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }
  }

  // .content::-webkit-scrollbar {
  //     width: 5px;
  // }
}
