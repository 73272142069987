.arrowBox {
  margin-top: 0.4rem;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: 450px) {
    display: none;
  }
  .arrow {
    transition: 0.3s all ease;
    position: absolute;
    right: 4px;
    top: 6px;
    cursor: pointer;
  }

  .arrow-reverse {
    transform: rotate(180deg);
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: 7px;
  }

  .arrow-drop {
    @extend .arrow;
    right: 3px;
    top: 7px;
    transform: rotate(180deg);
  }
}

.homeicons-menu {
  width: 40px;
  height: 40px;
}

.icon {
  border-radius: 10px;
  margin-bottom: 14px;
  height: 41px;
  display: flex;
  align-items: center;
  transition: 0.3s all ease;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  &:hover {
    transition: 0.3s all ease;
    background: #fff;
    box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
    border-radius: 10px;
  }
}

.icon-hovered {
  @extend .icon;
  background: #3853a3;
  @media only screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  &:hover {
    background: #3853a3;
    border-radius: 10px;
  }
}

.icon-text {
  transition: 0.3s all ease !important;
  color: #fff !important;
}

.flex {
  display: flex;
}

.flex-colum {
  display: flex;
  flex-direction: column;
}

.avatar {
  display: flex;
  margin: 50px 43px 10px 0;
  .avatar-img-box {
    width: 52px;
    height: 52px;
  }
  .avatar-text {
    margin-right: 20px;
    @media only screen and (max-width: 450px) {
      text-align: right;
    }
    .flex {
      margin-top: 4px;
      display: flex;
      .settings {
        display: block;
        padding-right: 10px;
        line-height: 20px;
        border-right: 1px solid #7e94ab;
        cursor: pointer;
        transition: 0.3s all ease;

        &:hover {
          color: #ffa800;
          transition: 0.3s all ease;
        }
      }

      .log-out {
        line-height: 20px;
        padding-left: 10px;
        display: block;
        cursor: pointer;

        &:hover {
          color: #ffa800;
          transition: 0.3s all ease;
        }
      }
    }
  }
}

// change password

.change-password-box {
  background: #ffffff;
  box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
  border-radius: 20px;
  padding: 27px 32px 48px 20px;
  margin-right: 43px;
  position: relative;
  @media only screen and (max-width: 1480px) {
    width: 80%;
    margin-inline: auto;
    margin-bottom: 43px;
  }

  .password-cross {
    position: absolute;
    content: "";
    right: 30px;
    top: 30px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      right: 15px;
      top: 15px;
    }
  }

  .password-header {
    display: block;
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
  }
}

.home-header-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
}

.hello-there {
  @media only screen and (max-width: 450px) {
    display: none;
  }
}

.hello-there-small {
  display: none;
  @media only screen and (max-width: 450px) {
    display: block;
    .hello {
      font-family: "Jost";
      font-style: normal;
      font-weight: 700;
      color: #3853a3;
      font-size: 32px;
    }
  }
}

.avatar {
  display: none;
  @media only screen and (max-width: 450px) {
    display: block;
  }
}

.stats-box-and-products {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: 40px;
  padding-bottom: 30px;
  @media only screen and (max-width: 1024px) {
    margin-right: 0;
    padding-bottom: 0;
    width: 100%;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1450px) {
    width: 85%;
    margin-right: 0;
    padding-bottom: 0;
  }
}

//products
.create-new-product {
  width: 1000px;
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    @media only screen and (max-width: 450px) {
      gap: 20px;
    }
    .input-box {
      display: flex;
      gap: 20px;
      margin-bottom: 52px;

      .right-side {
        display: flex;
        align-items: center;
        flex-direction: column;

        .upload-header {
          font-size: 20px;
          margin-top: 42px;
          margin-bottom: 39px;
          text-align: center;
        }

        .react-select-styling {
          width: 450px;
          margin-bottom: 27px;
          font-family: "Jost";
          font-style: normal;
          font-weight: 400;
          color: #3853a3;
          height: 100%;
          @media only screen and (max-width: 1200px) {
            width: 80%;
          }
        }
      }
    }

    .input-wrap {
      width: 450px;
      position: relative;
      display: flex;
      margin-bottom: 24px;
      height: 54px;
      outline: none;
      border: none;
      @media only screen and (max-width: 1200px) {
        width: 80%;
      }

      .create-shop-textarea {
        transition: 0.5s all ease;
        width: 450px;
        border-radius: 20px;
        padding: 20px 40px 0 20px;
        outline: none;
        border: none;
        height: 130px;
        font-family: "Jost";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #0e1425;
        z-index: 5;
        &::placeholder {
          font-family: "Jost";
          font-style: normal;
          font-weight: 400;
          color: #3853a3;
          transition: 0.5s all ease;
          height: 30px;
          font-size: 16px;
        }
      }

      input {
        transition: 0.5s all ease;
        width: 100%;
        border-radius: 50px;
        padding: 0 0 0 20px;
        height: 100%;
        margin-bottom: 27px;
        position: relative;
        border-radius: 20px;
        border: 1px solid #000;
        outline: none;
        border: none;
        z-index: 5;
        &::placeholder {
          color: #3853a3;
        }
      }

      .create-product-dropdown {
        position: relative;
        transition: 0.5s all ease;
        width: 100%;
        border-radius: 50px;
        padding: 0 0 0 20px;
        height: 100%;
        margin-bottom: 27px;
        border-radius: 20px;
        cursor: pointer;
        background-color: rgb(216, 224, 235);
      }

      .create-product-dropdown-box {
        position: absolute;
        width: 99%;
        left: 2px;
        background-color: rgb(239, 239, 239);
        border-radius: 10px;
        transition: 0.7s all ease;

        ul {
          list-style: none;
          padding: 20px;
          padding-top: 40px;

          li {
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            color: #3853a3;
          }
        }
      }

      .placeholder-box {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        left: 22px;
        transition: 0.5s all ease;

        .placeholder {
          font-family: "Jost";
          font-style: normal;
          font-weight: 400;
          color: #3853a3;
          transition: 0.5s all ease;
          height: 30px;
        }
      }

      .dropdown-placeholder-box {
        @extend .placeholder-box;
        z-index: 4;
        cursor: pointer;
      }

      img {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 10px;
        top: 13px;
        z-index: 5;
        cursor: pointer;
        opacity: 1;
        transition: 0.5s all ease;
      }

      .create-product-dropdown-arrow {
        width: 12px;
        height: 8px;
        top: 25px;
        right: 20px;
      }
    }

    .button-box {
      margin-bottom: 43px;

      button {
        padding: 10px 0;
        width: 143px;
        border-radius: 10px;
      }
    }
  }
}

.create-new-product-upload-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 650px) {
    flex-wrap: wrap;
  }

  .create-new-product-image-box {
    position: relative;

    @media only screen and (max-width: 650px) {
      margin-bottom: 20px;
    }

    .upload-cross {
      position: absolute;
      cursor: pointer;
      top: -8px;
      right: -4px;
      width: 24px;
      height: 24px;
    }

    .text {
      position: absolute;
      background-color: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-55%, -60%);
      width: 78px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      input[type="file"] {
        display: none;
      }

      > label {
        cursor: pointer;
        display: inline-block;
        font-size: 10px;
        font-family: "Jost";
        font-style: normal;
        font-weight: 600;
        color: #3853a3;
      }
    }

    img {
      width: 85px;
      height: 80px;
      margin-right: 10px;
      border-radius: 10px;
    }
  }
}

.create-new-product {
  padding-top: 10px;
  width: 1000px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .input-box {
      display: flex;
      gap: 13px;
      margin-bottom: 52px;
      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        width: 100%;
        margin-bottom: 0;
      }

      .left-side {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-inline: auto;
      }

      .right-side {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-inline: auto;

        .out-of-stock {
          width: 100%;
          display: flex;
          height: 45px;
          align-items: center;
          justify-content: flex-start;
          margin-left: 50px;
          gap: 15px;
          @media screen and (max-width: 1024px) {
            margin-left: 0;
            justify-content: center;
          }
          input {
            height: 20px;
            width: 20px;
            cursor: pointer;
          }
          .out-of-stock-header {
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            color: #3853a3;
            font-size: 19px;
            letter-spacing: 0.15px;
          }
        }

        .stock-box {
          width: 22px;
          height: 22px;
          border-radius: 5px;
          border: 1px solid #00000069;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          .stock-tick {
            top: 0;
            width: 18px;
            height: 18px;
          }
        }
        .upload-header {
          font-size: 20px;
          margin-top: 42px;
          margin-bottom: 2px;
        }

        .upload-recom {
          margin-bottom: 25px;
        }

        .upload-image {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .image-box {
            position: relative;
            cursor: pointer;

            .text {
              position: absolute;
              background-color: #fff;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -60%);
              width: 78px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;

              input[type="file"] {
                display: none;
              }

              > label {
                display: inline-block;
                font-size: 11px;
                font-family: "Jost";
                font-style: normal;
                font-weight: 600;
                color: #3853a3;
              }
            }

            img {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }

    .product-input-wrap {
      width: 450px;
      position: relative;
      display: flex;
      margin-bottom: 24px;
      height: 54px;
      outline: none;
      border: none;
      @media only screen and (max-width: 1200px) {
        width: 80%;
      }

      input {
        transition: 0.5s all ease;
        width: 100%;
        border-radius: 50px;
        padding: 0 0 0 20px;
        height: 100%;
        margin-bottom: 27px;
        position: relative;
        border-radius: 20px;
        color: #0e1425;
        border: 1px solid #000;
        outline: none;
        border: none;
        z-index: 5;
        &::placeholder {
          font-family: "Jost";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #3853a3;
        }
      }

      img {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 10px;
        top: 13px;
        z-index: 5;
        cursor: pointer;
        opacity: 1;
        transition: 0.5s all ease;
      }

      .placeholder-box {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        left: 22px;
        transition: 0.5s all ease;

        .placeholder {
          font-family: "Jost";
          font-style: normal;
          font-weight: 400;
          color: #3853a3;
          transition: 0.5s all ease;
          height: 30px;
        }
      }
    }

    .button-box {
      margin-bottom: 43px;

      button {
        padding: 10px 0;
        width: 143px;
        border-radius: 10px;
      }
    }
  }
}

.select-box {
  position: relative;
  height: 50px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

// profile section

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 1250px) {
    width: 100%;
  }

  .profile-avatar {
    display: flex;
    align-items: center;
    margin-top: 62px;
    margin-bottom: 64px;

    .text {
      font-size: 25px;
      margin-left: 20px;
    }
  }

  .user-info {
    width: 1100px;
    margin-top: 30px;

    @media only screen and (max-width: 1250px) {
      width: 100%;
    }
    @media only screen and (min-width: 1250px) {
      margin-inline: auto;
    }

    .user-info-content {
      background: #ffffff;
      box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
      border-radius: 20px;
      padding: 27px 32px 48px 20px;
      margin-right: 43px;

      @media only screen and (max-width: 1250px) {
        width: 80%;
        text-align: center;
        margin-inline: auto;
        padding: 20px;
      }

      .header {
        font-size: 25px;
        padding-left: 26px;

        @media only screen and (max-width: 1250px) {
          padding-left: 0;
        }
      }

      .all-inputs {
        padding-top: 39px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media only screen and (max-width: 1250px) {
          flex-direction: column;
        }
      }
    }

    .password-box {
      background: #ffffff;
      box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
      border-radius: 20px;
      margin: 39px 43px 142px 0px;

      @media only screen and (max-width: 1250px) {
        margin-inline: auto;
        width: 80%;
        text-align: center;
      }

      .password-box-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 27px 34px 27px 26px;

        @media only screen and (max-width: 1250px) {
          flex-direction: column;
        }

        .password {
          display: block;
        }
      }

      .text {
        display: flex;
        font-size: 25px;
      }
    }
  }
}

.password-btn {
  padding: 7px 15px;
  margin-bottom: -20px;
  margin-top: 20px;

  @media only screen and (max-width: 1250px) {
    margin-bottom: 0;
  }
}

.number-of-rows {
  font-weight: 700;
}

.pagination-left-arrow {
  transform: rotate(180deg) !important;
}

.filter-box {
  padding-top: 10px;
  padding-bottom: 10px;
}

.gray-border {
  border: 1px solid rgba(126, 148, 171, 0.1);
}

.border-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.border-padding-l {
  padding-left: 43px;
}

.customers-header {
  margin: 16px 0 14px 0;
}

.customer-table {
  .header-row {
    .customer-name {
      margin-right: 1rem;
    }
  }
}

.table-center {
  display: flex;
  justify-content: center;
}

.image-flex {
  display: flex;
  align-items: center;
}

.row {
  width: 100%;
  border-bottom: 1px solid #000;
}

.td-box {
  display: flex;
  align-items: center;
  justify-items: center;
}

.gray-text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #687b90;
  font-size: 14px;
  text-align: center;
  // @media only screen and (min-width: 769px) {
  //   text-align: center;
  // }
  @media (max-width: 1600px) {
    font-size: 14px;
  }
}

//input box

.create-new-section {
  width: 1000px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }

  form {
    width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }

    input,
    textarea {
      border-radius: 20px;
      outline: none;
      border: none;
      color: #0e1425;
      font-size: 14px;
    }

    input {
      width: 100%;
      margin-bottom: 28px;
      color: #0e1425;
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    textarea {
      margin-bottom: 43px;
      width: 100%;
      height: 100%;
      padding: 15px 40px 0 20px;

      &::placeholder {
        color: #3853a3;
      }
    }
  }

  .button-box {
    margin-bottom: 43px;
    button {
      padding: 10px 0;
      width: 143px;
      border-radius: 10px;
    }
  }
}

.remove {
  width: 111px;
  height: 33px;
  outline: none;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 19.5px;
    height: 21.5px;
  }

  .remove-text {
    color: #fff;
    font-family: "Jost";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    margin-left: 12px;

    &:hover {
      color: #3853a3;
    }
  }

  &:hover {
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s all ease;
    cursor: pointer;

    .remove-text {
      color: #ffa800;
      transition: 0.3s all ease;
    }
  }
}

.edit {
  @extend .remove;
  width: 89px;
}

.remove-blue {
  @extend .remove;
  background: #3853a3;

  &:hover {
    background-color: #fff;
  }
}

.general-input {
  width: 530px;
  position: relative;
  display: flex;
  margin-bottom: 24px;
  height: 54px;
  outline: none;
  border: none;

  input {
    transition: 0.5s all ease;
    width: 100%;
    border-radius: 50px;
    padding: 0 0 0 20px;
    height: 100%;
    margin-bottom: 27px;
    color: #374366;
    position: relative;
    border-radius: 20px;
    color: #0e1425;
    border: 1px solid #000;
    outline: none;
    border: none;
    z-index: 5;
  }

  img {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 10px;
    top: 13px;
    z-index: 5;
    cursor: pointer;
    opacity: 1;
    transition: 0.5s all ease;
  }
}
.placeholder-box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 22px;
  transition: 0.5s all ease;

  @media screen and (max-width: 768px) {
    left: 52px;
  }

  .placeholder {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    color: #3853a3;
    transition: 0.5s all ease;
    height: 30px;
  }
}

//attributes
.attribute-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  .input-shop {
    input {
      @media screen and (max-width: 1050px) {
        width: 95%;
        margin: auto;
        padding: 20px;
      }
    }
  }
  .attribute-button-box {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin-bottom: 51px;
    button {
      width: 140px;
      height: 33px;
      border-radius: 10px;
      @media only screen and (max-width: 480px) {
        width: 120px;
      }
    }
  }
}

.new-attribute-created {
  height: 20px;
  margin: 20px;
}

// demoshop home

.activity-box {
  display: flex;
  text-align: left;
  flex-direction: column;
}
.activity-order-gray-text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #7e94ab;
  font-size: 14px;
  text-align: left;
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    text-align: center;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
  }
}
.home-single-product {
  width: 180px;
  height: 40px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
}
.home-top-products {
  width: 700px;
  background: #ffffff;
  box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
  border-radius: 20px;
  @media only screen and (max-width: 1024px) {
    width: 95%;
    margin-inline: auto;
    margin-bottom: 40px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1450px) {
    width: 85%;
    margin-bottom: 0;
  }

  .home-top-products-header {
    font-size: 20px;
    padding: 22px 0 5px 15px;
    font-family: "Jost";
    font-style: normal;
    font-weight: 600;
    color: #3853a3;
    @media only screen and (max-width: 480px) {
      font-size: 18px;
    }
  }

  .home-products-table {
    width: 100%;

    :last-child {
      border-bottom: none;
    }

    tr {
      border-bottom: 1px solid rgba(126, 148, 171, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;

      td {
        padding: 0 15px 0 15px;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
}

// orders section

.orders-accepted-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(126, 148, 171, 0.1);
  width: 100%;
  @media only screen and (max-width: 1450px) {
    margin-inline: auto;
  }
  @media only screen and (min-width: 800px) and (max-width: 1450px) {
    width: 65%;
  }

  .orders-accepted-header {
    text-align: center;
    width: 157px;
    font-family: "Jost";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    padding: 20px 25px 20px 25px;
    display: inline;
    cursor: pointer;
    color: #3853a3;
    background-color: #fff;
    &:first-child {
      border-radius: 20px 0px 0px 0px;
    }
    @media only screen and (max-width: 1450px) {
      width: 100%;
      font-size: 15px;
      padding: 5px;
    }
  }
}

.orders-accepted-active {
  width: 157px;
  text-align: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  padding: 20px 25px 20px 25px;
  display: inline;
  cursor: pointer;
  color: #fff;
  background-color: #3853a3;

  &:first-child {
    border-radius: 20px 0px 0px 0px;
    @media screen and (max-width: 1450px) {
      border-radius: 0;
    }
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    font-size: 15px;
    padding: 20px 5px;
  }
}

.table_wrapper {
  width: 100%;
  @media screen and (max-width: 850px) {
    overflow-x: auto;
    padding-bottom: 20px;
    border-left: 15px solid #fff;
    border-right: 15px solid #fff;
  }
  &::-webkit-scrollbar {
    background: #3853a3;
    border-radius: 30px;
    height: 12.5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #ffa800;
  }
  .orders-table {
    border-collapse: collapse;
    width: 100%;
    @media only screen and (max-width: 600px) {
      width: 250%;
    }
    @media only screen and (min-width: 601px) and (max-width: 845px) {
      width: 200%;
    }

    .product-image {
      width: 41px;
      height: 41px;
      border-radius: 10px;
      margin-right: 18.9px;
    }

    .header {
      td {
        text-align: left;
      }
    }

    tr {
      border-bottom: 1px solid rgba(126, 148, 171, 0.1);
      height: 46px;

      td {
        text-align: left;

        &:first-child {
          div:first-child {
            border-left: 30px solid #fff;
          }
        }

        .orders-td-box-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .change-only-once {
          position: absolute;
          width: 150px;
          font-size: 10.5px;
          left: -17px;
          top: -22px;
        }

        &:last-child {
          .orders-td-box {
            border-right: 30px solid #fff;
            // display: flex;
            // justify-content: flex-end;
          }
        }
      }

      th:last-child {
        text-align: right;
        transform: translateX(-21px);
      }
    }

    .remove-btn {
      padding: 4px;
    }

    .availability {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #3853a3;
      width: 135px;
      height: 36px;
      border-radius: 10px;
      border: none;
      outline: none;
      @media only screen and (max-width: 480px) {
        width: 100px;
      }
      @media only screen and (min-width: 845px) and (max-width: 1200px) {
        width: 90px;
      }
    }
  }
  .orders-table-customers {
    border-collapse: collapse;
    width: 100%;
    @media only screen and (max-width: 600px) {
      width: 150%;
    }
    @media only screen and (min-width: 601px) and (max-width: 845px) {
      width: 120%;
    }

    .product-image {
      width: 41px;
      height: 41px;
      border-radius: 10px;
      margin-right: 18.9px;
    }

    .header {
      td {
        text-align: left;
      }
    }

    tr {
      border-bottom: 1px solid rgba(126, 148, 171, 0.1);
      height: 46px;

      td {
        text-align: left;

        &:first-child {
          div:first-child {
            border-left: 30px solid #fff;
          }
        }

        .orders-td-box-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .change-only-once {
          position: absolute;
          width: 150px;
          font-size: 10.5px;
          left: -17px;
          top: -22px;
        }

        &:last-child {
          .orders-td-box {
            border-right: 30px solid #fff;
            // display: flex;
            // justify-content: flex-end;
          }
        }
      }

      th:last-child {
        text-align: right;
        transform: translateX(-21px);
      }
    }
  }

  .table {
    @media only screen and (max-width: 600px) {
      width: 250%;
    }
    @media only screen and (min-width: 601px) and (max-width: 845px) {
      width: 200%;
    }
  }
}

.table-box-empty {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  gap: 15px;
  margin: 20px 0 25px 30px;

  @media only screen and (max-width: 1450px) {
    align-items: center;
    margin: 20px auto;
  }
}

.green {
  background: #bfe795;
}

.red {
  background: #e69595;
}

.yellow {
  background-color: #e9b528;
}

.accept-reject-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
  border-radius: 5px;
  .image-box-reject {
    background-color: #e69595;
  }
  .image-box-accept {
    background-color: #bfe795;
  }
  .accept-reject-image-box {
    border: 3px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    &:hover {
      border: 3px solid #7e94ab7a;
    }
    img {
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
      width: 25px;
      height: 25px;
    }
  }
}

.orders-dropdown {
  margin: auto;
  height: 30px;
  border-radius: 10px;
  z-index: 3;
  color: #3853a3;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 20px;
  @media only screen and (max-width: 450px) {
  }
  .orders-select-content {
    padding: 3px 0 0 0;
    width: 93%;
    display: flex;
    align-items: center;
    img {
      height: 5px;
      width: 8px;
    }
  }

  .active-selected {
    display: block;
    width: 100%;
    text-align: center;
    z-index: 3;
  }
}

.single-order-all-products {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  button {
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  img {
    width: 12px;
    cursor: pointer;
  }
}

.single-order-all-products-box {
  position: absolute;
  top: 23px;
  padding: 7px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  border-left: 1px solid #8e8e8e;
  border-right: 1px solid #8e8e8e;
  border-bottom: 1px solid #8e8e8e;
  border-top: 1px solid #8e8e8e;
  .single-order-all-products-ul {
    width: 185px;
    text-decoration: none;
    list-style: none;
    text-align: left;
    cursor: default;
    .item-quantity {
      color: green;
      border-right: 1px solid green;
      padding-right: 3px;
      margin-right: 8px;
      font-weight: 500;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 400;
    }
  }
}

.orders-ul {
  position: absolute;
  list-style: none;
  height: 23px;
  width: 88px;
  bottom: -9px;
  left: 18.5px;
  border-radius: 10px;
  z-index: 1;
  cursor: pointer;
}

.orders-li {
  height: 35px;

  .choose {
    :first-child {
      padding: 5px 0 0 3px;
    }

    padding: 3px 0 0 0;
    display: block;
    text-align: left;
    width: 100%;
    padding: 5px 0 0 9px;
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }
}

//categories section
.categories-table {
  border-collapse: collapse;
}

.td-category {
  text-align: center;
  @media only screen and (max-width: 620px) {
    width: 30%;
  }
  @media only screen and (min-width: 621px) and (max-width: 1200px) {
    width: 45%;
  }
}

.trash-btn-categories {
  width: 90%;

  @media only screen and (min-width: 480px) {
    border-right: 30px solid #fff;
  }
}

.categories-tr {
  .categories-td {
    text-align: left;
    @media only screen and (max-width: 1224px) {
      width: 30%;
    }

    &:first-child {
      .categories-td-box {
        border-left: 30px solid #fff;
      }
    }
  }
}
.trash-btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.trash-btn {
  width: 110px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: #3853a3;
  border-radius: 10px;
  transition: 0.3s all ease;
  position: relative;
  &:hover {
    .trash-btn-content {
      img {
      }
      .check-text {
        color: #ffa800;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  .trash-btn-content {
    display: flex;
    align-items: center;
    justify-content: center;

    .check-text {
      font-family: "Jost";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 30px;
      color: #fff;
    }
    @media only screen and (max-width: 480px) {
      padding: 5px 15px;
    }
    img {
      width: 19px;
      height: 21.5px;
      margin-right: 10px;

      @media only screen and (max-width: 480px) {
        margin-right: 0;
      }
    }

    .orange-trash {
      position: absolute;
      left: 18px;
    }
    .orange-edit {
      position: absolute;
      left: 22px;
    }

    .trash-text {
      font-family: "Jost";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 30px;
      color: #fff;
      @media only screen and (max-width: 480px) {
        font-weight: 300;
        font-size: 13px;
        display: none;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: #fff;
    transition: 0.3s all ease;
    border: 1px solid #ffa800;

    .trash-text {
      color: #ffa800;
    }
  }
}

.category-button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  margin: 10px 0 30px 0;
  button {
    padding: 10px 0;
    width: 143px;
    border-radius: 10px;
    @media only screen and (max-width: 480px) {
      width: 110px;
    }

    &:first-child {
      margin-right: 13px;
      @media only screen and (max-width: 480px) {
        margin-right: 5px;
      }
    }
  }
}

.trash-btn-disabled {
  @extend .trash-btn;
  background: rgba(126, 148, 171, 0.5);
  border: none;

  &:hover {
    background: rgba(126, 148, 171, 0.5);
    cursor: default;
    border: none;

    .trash-text {
      color: #fff;
    }
  }
}

// attributes

.attributes-tr {
  .attributes-td {
    text-align: left;

    @media only screen and (max-width: 480px) {
      width: 60%;
    }

    &:first-child {
      .td-box {
        border-left: 30px solid #fff;
      }
    }
  }
}

.attributes-btn {
  width: 110px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: #3853a3;
  border-radius: 10px;
  transition: 0.3s all ease;
}

.pagination {
  li {
    display: none;
  }
  display: flex;
  list-style: none;
  .active-page,
  .previous,
  .next {
    display: inline-block;
  }
  .active-page {
    margin-left: 10px;
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    color: #7e94ab;
    font-size: 14px;
  }
}

.products-tr {
  .products-td {
    .image-flex {
      border-left: 30px solid #fff;
    }
  }
  th {
    @media only screen and (min-width: 845px) and (max-width: 1200px) {
      border-left: 10px solid #fff;
    }
  }
}

// overall
.td-box-border-left {
  border-left: 30px solid #fff;
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#ordersSection,
#customerSection,
#homeSection,
#shopSection,
#categoriesSection,
#productsSection,
#attributesSection {
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  @media only screen and (min-width: 480px) and (max-width: 1450px) {
    margin-inline: auto;
    width: 60%;
  }
}

.button-box-edit-product {
  margin-bottom: 43px;
  margin-top: 30px;

  button {
    padding: 10px 0;
    width: 143px;
    border-radius: 10px;
    @media screen and (max-width: 450px) {
      width: 120px;
    }
    &:first-child {
      margin-right: 10px;
    }
  }
}

.demo-shop-header {
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  color: #3853a3;
  font-size: 20px;
  display: none;
  margin-top: 1px;
  @media only screen and (max-width: 450px) {
    display: block;
  }
}

.single-order-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 20px 20px;
  width: 100%;
}

.left-column,
.right-column {
  width: 180px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.order-info-type,
.order-info {
  font-family: "Jost";
  font-style: normal;
  height: 32px;
  @media only screen and (max-width: 450px) {
    height: 45px;
    font-size: 15px;
  }
}

.order-info-type {
  font-weight: 600;
  color: #3853a3;
}

.order-info {
  font-size: 14px;
  font-weight: 300;
}

.loading-spinner-gray {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 5px solid rgb(193, 193, 193);
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin-left: 110px;
  margin: auto;
}

.loading-spinner-picture {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 5px solid rgb(193, 193, 193);
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin-left: 110px;
  transform: translateX(-150px);
  margin: 150px auto auto auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pagination-elements {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  .current-page {
    margin-bottom: 4px;
  }
  .pagination-arrow {
    img {
      cursor: pointer;
    }
  }
}
.shop-pagination-elements {
  @extend .pagination-elements;
  justify-content: center;
  margin-bottom: 50px;
}

.created-product {
  margin-bottom: 20px;
}
