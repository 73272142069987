.shop-section {
  @media only screen and (max-width: 1500px) {
    height: 100%;
  }

  .yellow {
    width: 100%;
    height: 110px;
    background-color: #ffa800;
    position: relative;

    .header-image {
      img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 105px;
        height: 105px;
        bottom: -50px;
        border-radius: 15px;
        object-fit: cover;
      }
    }
  }

  .shop-pagination {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 15px 30px 15px 33px;
    @media screen and (max-width: 1024px) {
      justify-content: center;
    }
  }

  .header-text {
    text-align: center;

    .text-content {
      margin-top: 60px;
      margin-bottom: 77px;

      .header {
        line-height: 110px;
        font-size: 50px;
        display: block;
      }
    }

    .description {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      color: #687b90;
      font-size: 14px;
      width: 60%;
      margin: auto;
      @media only screen and (max-width: 450px) {
        width: 300px;
      }
    }
  }

  .shop-items-container {
    margin: auto;
    width: 1300px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 100px;

    @media only screen and (max-width: 1500px) {
      width: 80%;
      flex-direction: column;
      flex-wrap: nowrap;
      margin-bottom: 40px;
    }

    .single-item {
      background: rgba(126, 148, 171, 0.1);
      border-radius: 20px;
      width: 600px;
      margin: 0 0 42px 0;

      @media only screen and (min-width: 1500px) {
        &:nth-child(even) {
          margin-right: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 768px) {
        width: 80%;
        margin: 20px auto;
      }

      @media only screen and (min-width: 769px) and (max-width: 1500px) {
        margin: 20px auto;
      }

      .shop-content {
        display: flex;
        align-items: flex-start;
        padding: 31px 30px 26px 30px;

        @media only screen and (max-width: 768px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .image-box {
          width: 220px;
          height: 200px;
          border-radius: 25px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 25px;
          }
        }

        .text {
          margin-left: 47px;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 25px;

          @media only screen and (max-width: 768px) {
            width: 100%;
            text-align: center;
            align-items: center;
            margin-left: 0;
          }

          .single-item-desc {
            font-size: 15px;
            width: 100%;
            line-height: 17px;
            .view {
              font-size: 16px;
              cursor: pointer;
            }
          }

          .category {
            margin-top: 10px;
            margin-bottom: 5px;
          }

          .price {
            padding: 3px 0 15px 0;
            font-size: 30px;
          }
        }
      }
    }
  }
}
.add-btn {
  background-color: transparent;
  border: 2px solid #ff9900;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.5s all ease;
  position: relative;
  font-size: 16px;

  img {
    width: 24px;
    height: 24px;
    margin-left: 20px;
  }

  .add-to-cart-white {
    opacity: 0;
    position: absolute;
    left: 0px;
  }
  .add-btn-text {
    font-size: 16px;
    line-height: 23px;
    margin: 0;
    padding: 7px 26px 7px 10px;
  }

  &:hover {
    background-color: #ff9900;
    transition: 0.5s all ease;
    .add-to-cart-white {
      opacity: 1;
    }
    .add-btn-text {
      color: #fff;
    }
  }
}

.added-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #3853a3;
  transition: 0.5s all ease;
  border: 2px solid transparent;
  cursor: pointer;

  .added-btn-text {
    font-size: 16px;
    line-height: 23px;
    margin: 0;
    color: #fff;
    padding-right: 14px;
    padding: 7px 26px 7px 10px;
  }
  img {
    width: 24px;
    height: 24px;
    margin-left: 14px;
  }
  &:hover {
    background-color: #fff;
    transition: 0.5s all ease;
    border: 2px solid #3853a3;
    .added-btn-text {
      color: #3853a3;
    }
  }
}

.back-to-shop {
  padding: 5px 30px;
  margin-left: 30px;
  font-weight: 500;
  font-size: 18px;
  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border: 1px solid rgb(222 222 222 / 93%) !important;
  }
}

.pagination-products-list {
  width: 1300px;
  margin: auto;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 50px;
  li {
    display: none;
  }
  .active-page,
  .previous,
  .next {
    display: inline-block;
  }
  .active-page {
    margin-left: 10px;
    font-family: "Jost";
    font-style: normal;
    font-size: 19px;
    font-weight: 500;
    color: #7e94ab;
    text-align: center;
    margin: 0 17px;
    margin-top: 5px;
    color: #ffa800;
  }
}

.shop-box-pagination {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.empty-shop-box {
  background-color: #c7ced69b;
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 300px;
  text-align: center;
}

.shop-search {
  margin: auto;
  width: 700px;
  display: flex;
  position: relative;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 350px;
  }
}

.shop-dropdown {
  position: absolute;
  border-radius: 10px;
  background: rgba(126, 148, 171, 0.1);
  border: 1px solid #e7e7e7;
  top: 30px;
  width: 170px;
  right: 30px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  ul {
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      font-family: "Jost";
      text-align: left;
      font-style: normal;
      font-weight: 400;
      color: #3853a3;
      font-size: 14px;
      padding: 5px 0px;
      width: 100%;
      &:first-child {
        padding-top: 34px;
      }
      &:last-child {
        padding-bottom: 10px;
        border-radius: 0 0 10px 10px;
      }
      &:hover {
        background-color: #fff;
        cursor: pointer;
      }
      span {
        margin-left: 41px;
      }
    }
  }
}

.no-results-box {
  margin: auto;
}
