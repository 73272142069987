.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000033;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.modal {
  position: relative;
  z-index: 5;
  width: 700px;
  min-height: 180px;
  margin: auto;
  padding: 0 2rem;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: green;
  background: #3853a3;
  box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
  border-radius: 20px;
  @media only screen and (max-width: 850px) {
    width: 80%;
    min-height: 200px;
    padding: 2rem;
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .button-box {
    button {
      width: 120px;
      font-family: "Jost";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 30px;
      color: #ffa800;
      background: transparent;
      border: 1px solid #ffa800;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.3s all ease;
      @media only screen and (max-width: 480px) {
        width: 100px;
      }

      &:first-child {
        margin-right: 20px;
        @media only screen and (max-width: 480px) {
          margin-right: 0;
        }
      }

      &:hover {
        background-color: #fff;
        transition: 0.3s all ease;
        border: 1px solid transparent;
        transform: translateY(-3px);
      }
    }
  }
}
