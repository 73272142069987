.cart-section {
  padding-top: 110px;

  @media only screen and (max-width: 1500px) {
    text-align: center;
  }
  @media only screen and (max-width: 500px) {
    padding-top: 60px;
  }

  #emptyCart {
    @media only screen and (max-width: 1500px) {
      margin: 0 20px;
      text-align: center;
    }
  }
  .header {
    margin: 73px 0 82px 280px;
    font-size: 35px;
    line-height: 30px;

    @media only screen and (max-width: 1500px) {
      margin: 73px 0;
    }
  }

  .cart-container {
    margin-top: 53px;
    margin-bottom: 160px;

    .single-item {
      margin: auto;
      width: 1474px;
      background: rgba(126, 148, 171, 0.1);
      border-radius: 20px;
      margin-bottom: 19px;
      transition: 0.5s all ease;

      @media only screen and (max-width: 1500px) {
        width: 90%;
      }

      &:hover {
        cursor: pointer;
        transition: 0.5s all ease;
        box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.25);
        background-color: #fff;
      }

      .single-cart-item-content {
        padding: 26px 106px 25px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media only screen and (max-width: 1500px) {
          width: 90%;
          padding: 26px;
        }
        @media only screen and (max-width: 500px) {
          margin-right: 0;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
        }

        .left {
          display: flex;
          align-items: flex-start;
          @media only screen and (max-width: 1500px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .picture-box {
            margin-right: 33px;
            @media only screen and (max-width: 480px) {
              margin-right: 0;
            }

            img {
              width: 122px;
              height: 122px;
              border: 2px solid #3853a3;
              border-radius: 25px;
              object-fit: cover;
              @media only screen and (max-width: 480px) {
                width: 145px;
                height: 145px;
              }
            }
          }

          .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media only screen and (max-width: 480px) {
              align-items: center;
              margin-top: 10px;
            }
            .text-header {
              font-size: 25px;

              @media only screen and (max-width: 480px) {
                font-size: 20px;
              }
            }

            .category {
              font-size: 25px;
              @media only screen and (max-width: 480px) {
                font-size: 20px;
              }
            }

            .shop {
              font-size: 25px;

              @media only screen and (max-width: 480px) {
                font-size: 20px;
              }
            }
          }
        }

        .price-box {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          @media only screen and (max-width: 480px) {
            align-items: center;
            padding: 10px 0;
          }

          .price {
            color: #ffa800;
            font-size: 35px;

            @media only screen and (max-width: 480px) {
              font-size: 25px;
            }
          }

          .number {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 128px;
            font-size: 20px;
            line-height: 29px;

            .cart-box {
              border: 3px solid #ffa800;
              border-radius: 50%;
              position: relative;
              width: 45px;
              height: 45px;
              background-color: transparent;
              transition: 0.7s all ease;
              position: relative;
              &:hover {
                background-color: #3853a3;
                transition: 0.7s all ease;
                border: 3px solid #fff;
                .number-img {
                  background-color: #fff;
                }
              }

              @media only screen and (max-width: 480px) {
                width: 30px;
                height: 30px;
              }

              .white-cart {
                position: absolute;
              }

              .number-img {
                width: 20px;
                height: 2px;
                background-color: #ffa800;
              }
              .minus {
                position: absolute;
                top: 19px;
                left: 10px;

                @media only screen and (max-width: 480px) {
                  width: 15px;
                  top: 12px;
                  left: 5px;
                }
              }
              .plus {
                position: absolute;
                transform: rotate(90deg);
                width: 17px;
                top: 19px;
                left: 11px;

                @media only screen and (max-width: 480px) {
                  width: 15px;
                  top: 12px;
                  left: 5px;
                }
              }

              img {
                position: absolute;
                width: 30px;
                height: 30px;
                left: 6.3px;
                top: 7px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .total {
      width: 1474px;
      margin: auto;
      border-radius: 20px;
      margin-bottom: 19px;
      background: #ffa800;

      .cart-total-content {
        padding-left: 102px;
        padding-right: 106px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .total-button-box {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 25px;
          @media only screen and (max-width: 768px) {
            margin-bottom: 20px;
          }
        }

        .total-button-box-cart {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 25px;

          @media only screen and (max-width: 480px) {
            flex-direction: column;
            margin-bottom: 20px;
          }
          @media only screen and (min-width: 481px) and (max-width: 768px) {
            margin-bottom: 20px;
          }

          .proceed {
            padding: 16px 60px;
            font-size: 18px;
            line-height: 29px;
            transition: 0.5s all ease;
            border: 3px solid transparent;

            &:hover {
              transition: 0.5s all ease;
              background: transparent;
              border: 3px solid #fff;
              color: #fff;
            }

            @media only screen and (max-width: 1024px) {
              padding: 6px 20px;
              font-size: 16px;
            }
          }
        }

        .price-box {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: 30px;

          @media only screen and (max-width: 768px) {
            margin-top: 30px;
            align-items: center;
          }

          .price-header {
            font-size: 25px;
            line-height: 35px;
            margin-bottom: 20px;

            @media only screen and (max-width: 500px) {
              margin-bottom: 0;
            }

            @media only screen and (min-width: 501px) and (max-width: 768px) {
              font-size: 30px;
              margin-bottom: 5px;
            }
          }

          .total-price {
            font-size: 40px;
            line-height: 35px;

            @media only screen and (max-width: 500px) {
              font-size: 26px;
            }

            @media only screen and (min-width: 501px) and (max-width: 768px) {
              font-size: 30px;
            }
          }
        }

        @media only screen and (max-width: 768px) {
          padding: 10px;
          flex-direction: column-reverse;
        }

        @media only screen and (min-width: 769px) and (max-width: 1500px) {
          padding: 10px 30px;
          flex-direction: column-reverse;
        }
      }
      @media only screen and (max-width: 1500px) {
        width: 70%;
      }
    }
  }
}

.all-shops-btn {
  padding: 16px 60px;
  font-size: 18px;
  line-height: 29px;
  transition: 0.5s all ease;
  border: 3px solid transparent;

  &:hover {
    transition: 0.5s all ease;
    background: transparent;
    border: 3px solid #fff;
    color: #fff;
  }

  @media only screen and (max-width: 1024px) {
    padding: 6px 20px;
    font-size: 16px;
  }
}
