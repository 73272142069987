.inputbox {
  position: relative;
  &:focus-within {
    .cross {
      opacity: 1;
      transition: 0.5s all ease;
    }
  }
  .cross {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 10px;
    top: 13px;
    z-index: 5;
    opacity: 0;
    transition: 0.5s all ease;
    cursor: pointer;
  }
  .input {
    width: 385px;
    height: 54px;
    font-size: 15px;
    line-height: 22px;
    padding: 0 0 0 23px;
    color: #3853a3;
    border: 1px solid #3853a3;
    border-radius: 100px;
    margin-bottom: 24px;
    color: #000;
    &::placeholder {
      color: #3853a3;
    }
    &:focus {
      background-color: #e2e2e2;
      border: none;
      outline: none;
      transition: 0.5s all ease;
      .cross {
        opacity: 1;
        transition: 0.5s all ease;
      }
    }
  }
}

.correct-fields {
  padding-top: 30px;
  color: #e01f1f;
  font-family: "Jost";
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
}

.sign-in-up-box {
  background-color: #fff;
  margin-bottom: 93px;
  margin-top: 60px;
  border-radius: 15px;
  @media only screen and (max-width: 450px) {
    margin-top: 30px;
  }
}
.google-facebook-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 450px) {
  .sign-in-up-box {
    width: 320px;
  }
}

.remember-me-box {
  display: flex;
  justify-content: flex-start;
  width: 70%;
  @media only screen and (max-width: 450px) {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.login-message {
  margin: 20px 0;
}

.login-message-text {
  color: #e01f1f;
  font-family: "Jost";
  font-style: normal;
  font-size: 15px;
  text-align: center;
  width: 70%;
  margin: auto;
}
