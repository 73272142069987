.checkout-page-section {
  padding-top: 110px;
  @media only screen and (max-width: 480px) {
    padding-top: 50px;
  }
  .order-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: auto;
    width: 1469px;

    @media only screen and (max-width: 480px) {
      width: 360px;
    }

    @media only screen and (min-width: 481px) and (max-width: 768px) {
      width: 380px;
    }

    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      width: 650px;
    }

    @media only screen and (min-width: 1025px) and (max-width: 1500px) {
      width: 950px;
    }

    .header {
      font-size: 35px;
      line-height: 30px;

      @media only screen and (max-width: 1500px) {
        font-size: 2.5rem;
        line-height: 50px;
        width: 100%;
        text-align: center;
      }
    }

    .order-form {
      background: #ffffff;
      box-shadow: 0px 14px 30px rgba(56, 83, 163, 0.1);
      border-radius: 20px;
      margin-top: 53px;
      width: 1469px;
      padding: 57px 40px 40px 20px;
      margin-bottom: 40px;

      @media only screen and (max-width: 480px) {
        width: 100%;
        padding: 57px 20px 40px 20px;
      }

      @media only screen and (min-width: 481px) and (max-width: 768px) {
        width: 380px;
      }

      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        width: 650px;
      }

      @media only screen and (min-width: 1025px) and (max-width: 1500px) {
        width: 950px;
      }

      .order-header {
        font-size: 25px;
        line-height: 30px;
        margin: auto;
        width: 100%;
        text-align: center;
        display: block;
      }
      .inputs-box {
        width: 1050px;
        margin: auto;

        @media only screen and (max-width: 480px) {
          width: 250px;
        }

        @media only screen and (min-width: 481px) and (max-width: 768px) {
          width: 300px;
        }

        @media only screen and (min-width: 769px) and (max-width: 1024px) {
          width: 550px;
        }

        @media only screen and (min-width: 1025px) and (max-width: 1500px) {
          width: 800px;
        }
      }
      .all-inputs {
        margin-top: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .order-error-message-box {
          display: block;
          height: 30px;
          padding-top: 10px;
          width: 100%;
          .order-error-message {
            width: 100%;
            font-family: "Jost";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: rgb(201, 30, 30);
            display: block;
            text-align: center;
          }
        }
        .phone-input-box {
          width: 494px;
          height: 54px;
          background: rgba(114, 139, 166, 0.2);
          margin-bottom: 20px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          @media only screen and (max-width: 450px) {
          }
          div {
            &:first-child {
              margin-left: 20px;
            }
          }
          select {
            position: absolute;
            z-index: 1;
            border-radius: 20px;
            height: 35px;
          }
          option {
            width: 400px;
          }

          input {
            left: 70px;
            position: absolute;
            z-index: 2;
            outline: none;
            border: none;
            height: 30px;
            background-color: transparent;
            width: 350px;
            color: #374366;
            &::placeholder {
              font-family: "Jost";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              color: #3853a3;
            }
            @media only screen and (max-width: 1500px) {
              left: 0;
              width: 100%;
            }
            @media only screen and (max-width: 450px) {
              width: 130px;
              left: 70px;
            }
          }
        }
      }
    }
    .total {
      width: 1474px;
      margin: auto;
      border-radius: 20px;
      margin-bottom: 160px;
      background: #ffa800;
      @media only screen and (max-width: 480px) {
        width: 300px;
      }

      @media only screen and (min-width: 481px) and (max-width: 768px) {
        width: 380px;
      }

      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        width: 650px;
      }

      @media only screen and (min-width: 1025px) and (max-width: 1500px) {
        width: 950px;
      }
      .checkout-content {
        padding-left: 102px;
        padding-right: 106px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
          flex-direction: column-reverse;
        }
        .total-button-box {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 25px;
          @media only screen and (max-width: 480px) {
            gap: 15px;
          }
          .checkout-proceed {
            padding: 16px 60px;
            font-size: 18px;
            line-height: 29px;
            transition: 0.5s all ease;
            border: 3px solid transparent;

            @media only screen and (max-width: 480px) {
              padding: 6px 10px;
              font-size: 16px;
              line-height: 18px;
              white-space: nowrap;
            }

            @media only screen and (min-width: 481px) and (max-width: 768px) {
              padding: 10px 21px;
              font-size: 16px;
              line-height: 18px;
              white-space: nowrap;
            }

            @media only screen and (min-width: 769px) and (max-width: 1024px) {
              padding: 8px 25px;
              font-size: 18px;
              white-space: nowrap;
            }
            &:hover {
              transition: 0.5s all ease;
              background: transparent;
              border: 3px solid #fff;
              color: #fff;
            }
          }
        }
        .price-box {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: 30px;
          .price-header {
            font-size: 25px;
            line-height: 35px;
            margin-bottom: 20px;
            @media only screen and (max-width: 500px) {
              margin-bottom: 0;
              white-space: nowrap;
            }
            @media only screen and (min-width: 501px) and (max-width: 1024px) {
              font-size: 30px;
              margin-bottom: 5px;
            }
          }
          .total-price {
            font-size: 40px;
            line-height: 35px;
            @media only screen and (max-width: 500px) {
              font-size: 26px;
              align-self: center;
            }

            @media only screen and (min-width: 501px) and (max-width: 1024px) {
              font-size: 30px;
              align-self: center;
            }
          }
        }
      }
    }
  }
}

.country-select {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #374366;
  margin-left: 15px;
  cursor: default;
  position: relative;
  z-index: 3;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid white;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin-left: 110px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
