.redirect-section {
  padding-top: 232px;
  height: 90vh;
  .container {
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;

    @media only screen and (max-width: 1300px) {
      width: 80%;
    }
    .header {
      text-align: center;
      font-size: 25px;
      padding-bottom: 30px;
    }
    .verify {
      padding: 20px 45px;
      font-size: 20px;
      cursor: pointer;
      border: 2px solid transparent;
      transition: 0.5s all ease;
      &:hover {
        background-color: transparent;
        border: 2px solid #ffa800;
        color: #ffa800;
        transition: 0.5s all ease;
      }
    }
  }
}
