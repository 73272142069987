.home {
  position: relative;
  .home-text {
    margin-left: 71px;
    margin-top: -20px;
    width: 1003px;
    .montenegro {
      font-family: "Intel Clear Pro";
      text-transform: uppercase;
      font-style: normal;
      font-weight: 700;
      font-size: 220px;
      line-height: 200px;
      color: rgba(151, 186, 255, 0.49);
    }
    .demo-shop {
      background: #ffa800;
      border-radius: 100px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 30px;
      text-transform: capitalize;
      color: #ffffff;
      padding: 22px 39.5px;
      border: none;
      margin-left: 25px;
      transform: translateY(-78%);
    }
  }
}

.home-dropdown-box {
  position: absolute;
  left: 0;
  background-color: #ffa800;
  width: 98%;
  top: 38px;
  left: 5px;
  border-radius: 10px 0 20px 20px;
  z-index: 2;
  ul {
    padding-top: 35px;
    padding-bottom: 10px;
    list-style: none;
    li {
      margin-bottom: 5px;
      cursor: pointer;
      &:hover {
        span {
          color: #3853a3;
        }
        background-color: #fff;
      }
      span {
        font-family: "Jost";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #fff;
        font-size: 17px;
        padding-left: 55px;
        line-height: 29px;
      }
    }
  }
}
