.arrow-box-left {
  margin: 0 20px 380px 0;
  transition: 0.5s all ease;
  @media only screen and (max-width: 480px) {
    display: none;
  }

  &:hover {
    transform: translateX(-20px);
    transition: 0.5s all ease;
    @media only screen and (max-width: 480px) {
      transform: translateX(-5px);
    }

    img {
      filter: grayscale(100%);
      transition: 0.5s all ease;
    }
  }
}

.arrow-box-right {
  margin: 0 0 380px 20px;
  transition: 0.5s all ease;
  @media only screen and (max-width: 480px) {
    display: none;
  }

  &:hover {
    transform: translate(20px);
    transition: 0.5s all ease;
    @media only screen and (max-width: 480px) {
      transform: translateX(5px);
    }

    img {
      filter: grayscale(100%);
      transition: 0.5s all ease;
    }
  }
}

.shop-box {
  display: flex;
  width: 100%;
  z-index: 2;
  position: relative;
}

.opacity-left {
  position: relative;
  justify-content: center;

  background: linear-gradient(
    90deg,
    white 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0) 90%,
    white 100%
  );
  z-index: 4;
}

.recom-image-box:hover ~ .recom-shop-button {
  transition: 0.5s all ease;
  background: #ffa800;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
}

.column-single-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin-right: 0;
  @media only screen and (max-width: 450px) {
  }
}

.smaller-view-arrow {
  display: none;
  position: absolute;
  bottom: 330px;
  z-index: 5;
  @media only screen and (max-width: 450px) {
    display: block;
    bottom: 390px;
  }
  img {
    width: 35px;
  }
}

.left {
  left: 20px;
}

.right {
  right: 20px;
}

.loading-spinner-box {
  height: 350px;
  width: 100%;
  padding-top: 100px;
}

.loading-spinner-gray-recom-shops {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 5px solid rgb(193, 193, 193);
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  margin-left: 110px;
  margin: auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
