.navbar-container {
  padding: 9px 71px 0 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  .logo-and-nav {
    display: flex;
    align-items: center;


    .navbar-nav {
      ul {
        display: flex;
        margin-left: -2rem;

        li {
          list-style: none;
          margin: 45px 0 97px 0;

          a {
            padding: 9px 30.5px;
            text-decoration: none;
            color: #6686d0;
            font-family: "Inter";
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            border-radius: 100px;
            border: 1px solid transparent;

            &:active {
              border: 1px solid #6686d0;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .search-and-register {
    display: flex;
    margin: 45px 71px 97px 0;

    .search-box {
      position: relative;
      display: flex;
      border-radius: 100px;
      background-color: #3853a3;

      // border:1px solid #fff;
      .search-bar {
        width: 411px;
        display: flex;

        .search-icon {
          margin: 7px;
          width: 21px;
          height: 21px;
        }

        .input-wrap {
          position: relative;
          display: flex;
          align-items: center;

          &:after {
            position: absolute;
            display: block;
            content: "";
            width: 1px;
            height: 26px;
            background-color: #6686d0;
            right: 0px;
            top: 6px;
          }
        }

        input {
          border: none;
          width: 224px;
          outline: none;
          background-color: transparent;
          font-family: "Inter";
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;

          &::placeholder {
            color: #6686d0;
          }
        }
      }

      .our-shops-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 21px;

        .our-shops {
          font-family: "Inter";
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #fff;
        }

        .down-arrow {
          width: 10px;
          height: 5px;
          margin-left: 18px;
        }
      }
    }

    .register {
      display: flex;
      width: 100%;
      margin-left: 81px;
      button {
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        padding: 8px 29px;
        outline: none;
        cursor: pointer;
      }

      .log-in {
        background-color: #fff;
        border-radius: 100px;
        color: #4360bd;
        border: 1px solid transparent;
      }

      .register-btn {
        border: 1px solid #6686d0;
        border-radius: 100px;
        margin-left: 11px;
        background-color: transparent;
        color: #fff;
      }
    }
  }
}

.search-box {
  .cart-box {
    background: #4360bd;
    transition: 0.3s all ease;

    &:hover {
      background: #fff;
      transition: 0.3s all ease;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}

.cart-link {
  position: absolute;
  left: -75px;
  top: 0;
}

#logo {
  @media only screen and (max-width: 1400px) {
    width: 100%;
  }
}

#log_res {
  display: flex;
  margin-top: 20px;
}

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  inset: 0;
  background: black;
  opacity: 0.6;
  z-index: -1;
  @media only screen and (min-width: 1400px) {
    display: none;
  }
}

.show {
  transition: transform 0.5s ease-in-out;
  transform: translateY(0);
}

.hide {
  transition: transform 0.5s ease-in-out;
  transform: translateY(-150%);
}

.show_overlay {
  transition: transform 0.5s ease-in-out;
  transform: translateY(0);
}

.hide_overlay {
  transition: transform 0.5s ease-in-out;
  transform: translateY(-150%);
}

.nav-avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #ffffff72;
}

#logo1 {
  line-height: 0;
  background-color: #FFFFFFb3;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
}

.mojBiz {
  width: 120% !important;
}