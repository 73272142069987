.order-placed-section {
  padding-top: 232px;

  @media only screen and (max-width: 450px) {
    padding-top: 100px;
  }
  .order-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: auto;
    width: 1469px;
    height: 70vh;

    @media only screen and (max-width: 1550px) {
      width: 80%;
      height: 90vh;
      text-align: center;
      align-items: center;
    }
    .header {
      font-size: 70px;
      line-height: 30px;

      @media only screen and (max-width: 1300px) {
        font-size: 60px;
        text-align: center;
        width: 100%;
      }
    }
    .order-placed {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 83px;

      .order-number {
        font-size: 48px;
        line-height: 30px;
        margin-bottom: 25px;

        @media only screen and (max-width: 1500px) {
          font-size: 30px;
          line-height: 60px;
        }
      }
      .thanks {
        font-size: 25px;
        line-height: 30px;
      }
    }
    .proceed {
      padding: 16px 60px;
      font-size: 20px;
      line-height: 29px;
      transition: 0.5s all ease;
      border: 3px solid transparent;
      margin-top: 50px;
      &:hover {
        transition: 0.5s all ease;
        background: transparent;
        border: 3px solid #ffa800;
      }
    }
  }
}
